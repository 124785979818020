:root {
  /* css variables */
  --bg1: #fff;
  --fg1: #000;

  --tba: lightgrey;
  --tbb: black;

  --status-neutral: #333;
  --status-offline: #F00;
  --status-partial: #FF0;
  --status-online: #0F0;

  --active-status: var(--status-neutral);

  /* actual root styles */
  background-color: var(--bg1);
  -webkit-user-select: none; /* Safari */
  user-select: none; /* Standard syntax */
}
:root:has(> .dark-theme){
  --bg1: #101010;
  --fg1: #181818;
  --tba: #565656;
  --tbb: #7a7a7a;
  color: white;
  .big-logo{
    filter: invert(100%);
  }
  .access-status-indicator{
    filter: invert(100%);
  }
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: scroll;
  flex-direction: column;
  align-items: center;
}

.center {
  text-align: center;
}

.flex-center {
  align-content: center;
  justify-content: center;
}

/*
  Header & navigation
 */

$header-base-height: 50px;
$header-indicator-height: 5px;
$header-full-height: $header-base-height + $header-indicator-height;

header {

  height: $header-full-height;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: 2px solid var(--tba);

  .access-status-indicator {
    border-bottom: $header-indicator-height solid var(--active-status);

    &.online {
      --active-status: var(--status-online);
    }

    &.offline {
      --active-status: var(--status-offline);
    }

    &.partial {
      --active-status: var(--status-partial);
    }
  }

  fa-profile-widget {
    float: right;
    text-align: right;
    margin-right: 5px;
    margin-top: 5px;
  }

}

aside {
  display: none;
  position: fixed;
  top: $header-full-height;
  width: 40%;
  height: calc(100vh - $header-full-height);
  background-color: transparent;

  &.active {
    display: block;
    height: fit-content;
    z-index: 1;
  }

  ul {
    background-color: var(--bg1);
    list-style-type: none;
    padding: 0;
    margin: 1px 0 0;

    li {
      padding: 0.2em;
    }
  }
}

#loading-indicator {
  position: relative;
  top: calc((100vh - 200px) / 2 - $header-full-height);

  &.natural-pos {
    position: static;
  }
}

.spinner {
  animation: rotation 5s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* Border style simulating html tables */
.border-outer {
  border: 1px solid var(--tba);
}

.border-inner {
  border-left: 1px solid var(--tbb);
  border-top: 1px solid var(--tbb);
  border-right: 1px solid var(--tba);
  border-bottom: 1px solid var(--tba);
  margin: 2px;
}

dialog::backdrop {
  background-color: #00000040;
}

.table-rule {
  border-top: 1px solid #0007;
}