:root {
  --bg1: #fff;
  --fg1: #000;
  --tba: lightgrey;
  --tbb: black;
  --status-neutral: #333;
  --status-offline: red;
  --status-partial: #ff0;
  --status-online: #0f0;
  --active-status: var(--status-neutral);
  background-color: var(--bg1);
  -webkit-user-select: none;
  user-select: none;
}

:root:has( > .dark-theme) {
  --bg1: #101010;
  --fg1: #181818;
  --tba: #565656;
  --tbb: #7a7a7a;
  color: #fff;
}

:root:has( > .dark-theme) .big-logo, :root:has( > .dark-theme) .access-status-indicator {
  filter: invert();
}

body {
  flex-direction: column;
  height: 100vh;
  margin: 0;
  display: flex;
}

main {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  display: flex;
  overflow: scroll;
}

.center {
  text-align: center;
}

.flex-center {
  place-content: center;
}

header {
  border-bottom: 2px solid var(--tba);
  flex-grow: 0;
  flex-shrink: 0;
  height: 55px;
}

header .access-status-indicator {
  border-bottom: 5px solid var(--active-status);
}

header .access-status-indicator.online {
  --active-status: var(--status-online);
}

header .access-status-indicator.offline {
  --active-status: var(--status-offline);
}

header .access-status-indicator.partial {
  --active-status: var(--status-partial);
}

header fa-profile-widget {
  float: right;
  text-align: right;
  margin-top: 5px;
  margin-right: 5px;
}

aside {
  background-color: #0000;
  width: 40%;
  height: calc(100vh - 55px);
  display: none;
  position: fixed;
  top: 55px;
}

aside.active {
  z-index: 1;
  height: -moz-fit-content;
  height: fit-content;
  display: block;
}

aside ul {
  background-color: var(--bg1);
  margin: 1px 0 0;
  padding: 0;
  list-style-type: none;
}

aside ul li {
  padding: .2em;
}

#loading-indicator {
  position: relative;
  top: calc(50vh - 155px);
}

#loading-indicator.natural-pos {
  position: static;
}

.spinner {
  animation: 5s linear infinite rotation;
}

@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.border-outer {
  border: 1px solid var(--tba);
}

.border-inner {
  border-left: 1px solid var(--tbb);
  border-top: 1px solid var(--tbb);
  border-right: 1px solid var(--tba);
  border-bottom: 1px solid var(--tba);
  margin: 2px;
}

dialog::backdrop {
  background-color: #00000040;
}

.table-rule {
  border-top: 1px solid #0007;
}

/*# sourceMappingURL=index.508fc352.css.map */
